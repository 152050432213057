import { useRef } from 'react';
import React from 'react';
import { closeAllMenu, initializeProcessFunctions, convert_data } from './import/OpenSankey';
import { MenuConfigHideLoneNode, hiddeLoneNodes } from './HideLoneNodeFunction';
import { SankeyDevDrawNodesNotToScale, SankeyDevNodesAttributes } from './NodeNotToScale';
import { SankeyDevMenuDataPercent, devGetLinkValue } from './PercentValue';
import { PublishModal, SankeyDevMenuPublishItem } from './PublishModale';
import { MenuEnregistrerExcelSimple } from './MenuEnregistrerExcelSimple';
import { itemDemo } from './MenuExampleDemo';
import { SankeyDevInputDialogSaveJSON } from './SankeyDevMenuDialog';
import { OSPInitializeApplicationData } from './import/OpenSankey+';
import { ModalUnitarySankeySpecialLinkValue, UnitarySankeyCreationDialog, SankeyDevBannerUnit, context_node_view_node_unitary } from './UnitarySankey/UnitarySankeyDialogs';
import { SankeyDev_link_text } from './UnitarySankey/UnitarySankeyDraw';
export const OSDevDefaultData = () => {
    return {};
};
export const OSDevInitializeApplicationContext = () => {
    return {
        has_sankeydev: false
    };
};
export const OSDevInitializeApplicationData = (data, set_data, get_default_data, display_nodes, display_links) => {
    const OSP_init_app_data = OSPInitializeApplicationData(data, set_data, get_default_data, display_nodes, display_links);
    const data_plus = data;
    const dev_display_nodes = display_nodes;
    const dev_display_links = display_links;
    const set_data_plus = set_data;
    const dev_get_defaut_data = get_default_data;
    const dev_var = {
        data: data_plus,
        set_data: set_data_plus,
        display_nodes: dev_display_nodes,
        display_links: dev_display_links,
        master_data: OSP_init_app_data.master_data,
        set_master_data: OSP_init_app_data.set_master_data,
        view: OSP_init_app_data.view,
        set_view: OSP_init_app_data.set_view,
        get_default_data: dev_get_defaut_data
    };
    return Object.assign(Object.assign({}, OSP_init_app_data), dev_var);
};
export const OSDevInitializeElementSelected = () => {
    return {
        multi_selected_nodes: useRef([]),
        ref_getter_beautify: useRef(false)
    };
};
export const OSDevInitializeShowDialog = () => {
    return {
        ref_setter_show_publish_dialog: useRef(() => null),
        ref_setter_show_resolution_save_png: useRef(() => null),
        ref_setter_show_modal_edit_link_reference_unitary: useRef(() => null),
        showUnitarySankeyCreationDialog: useRef(() => null),
        ref_setter_show_unitary_view_modal: useRef(() => null),
        ref_setter_is_for_unitary_view: useRef(() => null)
    };
};
export const OSDevcloseAllMenu = closeAllMenu;
// Modify Application Draw
export const OSDevInitializeApplicationDraw = () => {
    const _ = {};
    return _;
};
export const OSDevInitializeComponentUpdater = () => {
    const _ = {};
    return _;
};
export const OSDevInitializeReinitialization = () => () => {
};
export const OSDevInitializeProcessFunctions = initializeProcessFunctions;
// Modify context menu
export const OSDevInitializeContextMenu = () => {
    return {};
};
// Modify Ref used to open accordion item
export const OSDevInitializeUIElementsRef = () => {
    return {};
};
export const OSDevInitializeLinkFunctions = (applicationData) => {
    const devApplicationData = applicationData.data;
    if (devApplicationData.unitary_node && devApplicationData.unitary_node.length > 0) {
        return {
            GetLinkValue: devGetLinkValue,
            LinkText: SankeyDev_link_text
        };
    }
    return {
        GetLinkValue: devGetLinkValue
    };
};
export const OSDevInitializeNodeFunctions = (applicationData) => {
    const dev_app_data = applicationData;
    return {
        reDrawNodeNotToScale: (nodes_to_update) => {
            SankeyDevDrawNodesNotToScale(dev_app_data, nodes_to_update);
        }
    };
};
export const OSDevInitializeAdditionalMenus = (additionalMenus, applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState, uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, processFunctions, Reinitialization, contextMenu) => {
    if (window.SankeyToolsStatic) {
        return;
    }
    const dev_app_data = applicationData;
    const DevApplicationState = applicationState;
    // Top Menus
    additionalMenus.external_file_item.push(React.createElement(SankeyDevMenuPublishItem, { t: applicationContext.t, dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_components }));
    // Links
    additionalMenus.additional_data_element.push(React.createElement(SankeyDevMenuDataPercent, { data: applicationData.data, set_data: applicationData.set_data, multi_selected_links: applicationState.multi_selected_links }));
    additionalMenus.advanced_appearence_content.push(React.createElement(SankeyDevNodesAttributes, { t: applicationContext.t, data: applicationData.data, applicationState: DevApplicationState, is_activated: true, node_function: node_function, menu_for_style: false }));
    additionalMenus.advanced_appearence_content.push(React.createElement(MenuConfigHideLoneNode, { t: applicationContext.t, data: applicationData.data, set_data: applicationData.set_data, applicationState: DevApplicationState, menu_for_style: false }));
    // Otion in dropdown save sankey 
    additionalMenus.externale_save_item.push(MenuEnregistrerExcelSimple(applicationContext.url_prefix, applicationData.data));
    // Option in save json modal 
    additionalMenus.additional_file_save_json_option.push(React.createElement(SankeyDevInputDialogSaveJSON, { t: applicationContext.t, elementsSelected: applicationState }));
    additionalMenus.additional_file_item.push(React.createElement(SankeyDevMenuPublishItem, { t: applicationContext.t, dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_components }));
    additionalMenus.additional_context_element_other.push(context_node_view_node_unitary(applicationContext, dev_app_data, contextMenu, DevApplicationState));
    additionalMenus.sankey_menus['unit'] = React.createElement(SankeyDevBannerUnit, { applicationData: applicationData, t: applicationContext.t, connected: true, dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_components, 
        //selector_of_view // TODO
        view_selector: React.createElement(React.Fragment, null) }),
        additionalMenus.sankey_menus['demo'] = itemDemo(applicationContext, applicationData, applicationState, additionalMenus.example_menu, processFunctions.launch, Reinitialization);
};
export const OSDevModuleDialogs = (applicationContext, applicationData, applicationState, contextMenu, applicationDraw, uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, ComponentUpdater, additional_menus, menu_configuration_nodes_attributes, reDrawLegend, processFunctions) => {
    if (window.SankeyToolsStatic) {
        return [];
    }
    const OSDev_dict_app_data = applicationData;
    const OSDev_hook_show_dialogs = dict_hook_ref_setter_show_dialog_components;
    const OSDev_app_context = applicationContext;
    const module_dialogs = [];
    if (OSDev_app_context.has_sankeydev) {
        // Add modale to choose nodes to create unitary sankey from an excel file
        module_dialogs.push(React.createElement(UnitarySankeyCreationDialog, { applicationContext: applicationContext, applicationState: applicationState, applicationData: applicationData, processFunctions: processFunctions, applicationDraw: applicationDraw, convert_data: convert_data, dict_hook_ref_setter_show_dialog_components: OSDev_hook_show_dialogs }));
        module_dialogs.push(React.createElement(ModalUnitarySankeySpecialLinkValue, { applicationData: applicationData, t: applicationContext.t, dict_hook_ref_setter_show_dialog_components: OSDev_hook_show_dialogs }));
        const publishImpl = (file_path) => {
            const path = window.location.origin;
            const url = path + '/sankeydev/sankey/publish';
            const new_data = JSON.parse(JSON.stringify((OSDev_dict_app_data.master_data && OSDev_dict_app_data.view !== 'none') ? OSDev_dict_app_data.master_data : OSDev_dict_app_data.data));
            new_data.file_name = file_path;
            const fetchData = {
                method: 'POST',
                body: JSON.stringify(new_data, null, 2)
            };
            fetch(url, fetchData).then(response => {
                if (!response.ok) {
                    alert(response);
                }
            });
            OSDev_hook_show_dialogs.ref_setter_show_publish_dialog.current(false);
        };
        // Add modale configure parametrer before publishing a sankey
        module_dialogs.push(React.createElement(PublishModal, { t: applicationContext.t, publishImpl: publishImpl, dict_hook_ref_setter_show_dialog_components: OSDev_hook_show_dialogs, data: OSDev_dict_app_data.data }));
    }
    return module_dialogs;
};
export const OSDevDrawAll = (contextMenu, applicationData) => {
    // hiddeLoneNodes => Function that search and hide node that have 1 input link, 1 output link and have hideLoneNode at true
    // Example ('-->' represent a link):
    // node0-->node1-->node2
    // If we click on 'hide lone node' in the configuration menu  while node1 is selected then we get :
    // node0-->node2
    hiddeLoneNodes({
        data: applicationData.data,
        display_nodes: applicationData.display_nodes,
        display_links: applicationData.display_links,
        set_data: applicationData.set_data
    });
    // If we have the sankeydev token then 'restore' all node who had previously 'hideLoneNode' at true
    // but at this render it's false
    // Else if we don't have sasnkeydev token we restore all node event if 'hideLoneNode' is true
    // searchAndRestoreLoneNodes(data,set_data,has_sankeydev)
};
export const OSDevInstallEventsOnSVG = () => { };
