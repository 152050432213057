import * as AFMTooltip from 'afmsankey/dist/src/lib/SankeyTooltip'
import * as AFMSankeyMenu from 'afmsankey/dist/src/lib/AFMSankeyMenu'
import * as AFMFreeVariables from 'afmsankey/dist/src/lib/FreeVariables'
import * as AFMReconciliation from 'afmsankey/dist/src/lib/SankeyReconciliation'
import * as AFMModule from 'afmsankey/dist/src/AFMModule'
import {
  AFMEditionMenuFType
} from 'afmsankey/types/AFMSankeyMenuTypes'
import { FreeVariablesFType } from 'afmsankey/types/FreeVariablesTypes'
import { FunctionComponent } from 'react'
import {SupplyUseModelisationProdTypes} from 'afmsankey/types/SankeyReconciliationTypes'
import { initializeApplicationContextType, initializeElementSelectedType, initializeApplicationDrawType, initializeShowDialogType, initializeComponentUpdaterType, initializeReinitializationType, initializeContextMenuType, initializeUIElementsRefType, initializeLinkFunctionsType, initializeNodeFunctionsType, initializeAdditionalMenusType, module_dialogsType, DrawAllType, InstallEventsOnSVGType, initializeApplicationDataType } from 'open-sankey/src/types/Types'
import { LinkTooltipsContentFType } from 'open-sankey/src/draw/types/SankeyTooltipTypes'
export const AFMLinkTooltipsContent =
  AFMTooltip.AFMLinkTooltipsContent as LinkTooltipsContentFType
export const FreeVariables =
  AFMFreeVariables.FreeVariables as FreeVariablesFType
export const AFMEditionMenu =
  AFMSankeyMenu.AFMEditionMenu as AFMEditionMenuFType

export const SupplyUseModelisationProd:FunctionComponent<SupplyUseModelisationProdTypes>=AFMReconciliation.SupplyUseModelisationProd

export const AFMInitializeApplicationContext = AFMModule.AFMInitializeApplicationContext as initializeApplicationContextType
export const AFMinitializeApplicationData = AFMModule.AFMInitializeApplicationData as unknown as initializeApplicationDataType
export const AFMInitializeElementSelected = AFMModule.AFMInitializeElementSelected as unknown as initializeElementSelectedType
export const AFMInitializeApplicationDraw = AFMModule.AFMInitializeApplicationDraw as  unknown as initializeApplicationDrawType
export const AFMInitializeShowDialog = AFMModule.AFMInitializeShowDialog as unknown as initializeShowDialogType
export const AFMInitializeComponentUpdater = AFMModule.AFMInitializeComponentUpdater as unknown as initializeComponentUpdaterType
export const AFMInitializeReinitialization  = AFMModule.AFMInitializeReinitialization as initializeReinitializationType
export const AFMInitializeContextMenu = AFMModule.AFMInitializeContextMenu as initializeContextMenuType
export const AFMInitializeUIElementsRef = AFMModule.AFMInitializeUIElementsRef as initializeUIElementsRefType
export const AFMInitializeLinkFunctions  = AFMModule.AFMInitializeLinkFunctions as unknown as initializeLinkFunctionsType
export const AFMInitializeNodeFunctions = AFMModule.AFMInitializeNodeFunctions as unknown as  initializeNodeFunctionsType
export const AFMInitializeAdditionalMenus = AFMModule.AFMInitializeAdditionalMenus as initializeAdditionalMenusType
export const AFMModuleDialogs = AFMModule.AFMModuleDialogs as module_dialogsType
export const AFMDrawAll = AFMModule.AFMDrawAll as DrawAllType
export const AFMInstallEventsOnSVG = AFMModule.AFMInstallEventsOnSVG as InstallEventsOnSVGType