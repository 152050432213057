// External libs
import React, { useState } from 'react';
import { Checkbox } from '@chakra-ui/react';
import { ValueSelectedParameter } from '../import/OpenSankey';
export const FreeVariables = ({ t, applicationData, applicationState, node_function, link_function }) => {
    const { data } = applicationData;
    const [forceUpdate, setForceUpdate] = useState(true);
    const { multi_selected_links } = applicationState;
    const newEntries = new Map(Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
        return (Object.keys(dataTag.tags).length > 0) ? [
            dataTagKey,
            Object.entries(dataTag.tags).filter(tag => tag[1].selected).length > 0 ? Object.entries(dataTag.tags).filter(tag => tag[1].selected)[0][0] : Object.keys(dataTag.tags)[0]
        ] : ['n', 'n'];
    }));
    const tags_selected = Object.fromEntries(newEntries);
    const value_selected = ValueSelectedParameter(applicationData, multi_selected_links, tags_selected);
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: (value_selected && value_selected.extension && value_selected.extension.display_thin), onChange: (evt) => {
                let val = Object(multi_selected_links.current[0].value);
                const node_to_update = [];
                multi_selected_links.current.map(d => {
                    node_to_update.push(data.nodes[d.idSource]);
                    node_to_update.push(data.nodes[d.idTarget]);
                    val = d.value;
                    Object.values(tags_selected).forEach(tag => {
                        if (val[tag] === undefined) {
                            val[tag] = {};
                        }
                        val = val[tag];
                    });
                    val.extension.display_thin = evt.target.checked;
                });
                node_function.RedrawNodes(node_to_update);
                link_function.RedrawLinks(multi_selected_links.current);
                setForceUpdate(!forceUpdate);
            } }, t('Flux.data.astr'))));
};
