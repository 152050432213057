// External lib
import React, { useState, useRef } from 'react';
import * as d3 from 'd3';
import { FaEyeSlash, FaFileImport, FaLock, FaLockOpen } from 'react-icons/fa';
import { Box, Button, Checkbox, TabPanel, Input, InputGroup } from '@chakra-ui/react';
import { faIcons } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { OSPReturnValueLink } from './SankeyPlusUtils';
import { AssignNodeValueToCorrectVar, drag_node_text, DragElements, IsAllNodeAttrSameValue, IsNodeDisplayingValueLocal, LinkStrokeOSTyped, OpposingDragElements, RemoveAnimate, ReturnOutOfBoundElement, ReturnValueLink, ReturnValueNode, SimpleGNodeClick, NodeColor, SvgDragMiddleMouseMove, SvgDragMiddleMouseStart, actualizeDrawAreaFrame, hideLinkOnDragElement, OSTooltip, computeHorizontalIndex, } from './import/OpenSankey';
// OpenSankey js-code
import { TooltipValueSurcharge } from 'open-sankey/dist/configmenus/SankeyUtils';
export const OSPNodeIcon = ({ t, data, multi_selected_nodes, is_activated, menu_for_modal, dict_hook_ref_setter_show_dialog_components, node_function, ComponentUpdater }) => {
    const [show_menu_node_icon, set_show_menu_node_icon] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_node_icon.current = set_show_menu_node_icon;
    const data_plus = data;
    data_plus.icon_catalog = (data_plus.icon_catalog) ? data_plus.icon_catalog : {};
    const _load_image = useRef(null);
    const multi_selected_nodes_plus = multi_selected_nodes;
    const isAllNodeVisible = IsAllNodeAttrSameValue(data, multi_selected_nodes.current, ['shape_visible'], false)['shape_visible'];
    let all_are_icons = (Object
        .values(data_plus.nodes)
        .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
        .length > 0);
    let all_are_images = all_are_icons;
    let all_are_none = all_are_icons;
    Object
        .values(data_plus.nodes)
        .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
        .forEach(d => {
        all_are_icons = (all_are_icons && d.iconVisible);
        all_are_images = (all_are_images && d.is_image);
        all_are_none = (all_are_none && (!d.iconVisible && !d.is_image));
    });
    let button_icon_or_image = 'both';
    if (all_are_icons) {
        button_icon_or_image = 'icon';
    }
    if (all_are_images) {
        button_icon_or_image = 'image';
    }
    if (all_are_none) {
        button_icon_or_image = 'none';
    }
    // Content if we want to add icon to node
    const content_icon = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, button_icon_or_image === 'icon' ?
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
            React.createElement(OSTooltip, { label: !is_activated ? t('Menu.sankeyOSPDisabled') : '' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.icon.icon_catalog')),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', disabled: !is_activated, onClick: () => {
                            dict_hook_ref_setter_show_dialog_components
                                .ref_setter_show_modal_import_icons
                                .current(true);
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faIcons })))),
            React.createElement(OSTooltip, { label: !is_activated ? t('Menu.sankeyOSPDisabled') : '' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_3cols' },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.apparence.Couleur')),
                    React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: (multi_selected_nodes.current.length === 1) ?
                            multi_selected_nodes_plus.current[0].iconColor :
                            '#ffffff', onChange: evt => {
                            const color = evt.target.value;
                            Object
                                .values(data_plus.nodes)
                                .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                .map(d => d.iconColor = color);
                            node_function.reDrawIllustration(multi_selected_nodes.current);
                            setForceUpdate(!forceUpdate);
                        } }),
                    React.createElement(Button
                    //Si la valeur est a true alors la couleur des noeuds reste celle sélectionné loreque que l'on affiche les flux celon leur étiquettes
                    , { 
                        //Si la valeur est a true alors la couleur des noeuds reste celle sélectionné loreque que l'on affiche les flux celon leur étiquettes
                        variant: (multi_selected_nodes.current.length === 1) ?
                            'menuconfigpanel_option_button_activated' :
                            'menuconfigpanel_option_button', onClick: () => {
                            multi_selected_nodes.current.forEach(d => d.iconColorSustainable = !d.iconColorSustainable);
                            setForceUpdate(!forceUpdate);
                        } }, (multi_selected_nodes.current.length === 1 && multi_selected_nodes.current[0].iconColorSustainable) ? React.createElement(FaLock, null) : React.createElement(FaLockOpen, null))))) :
        React.createElement(React.Fragment, null));
    // Content if we want to add image to node
    const content_image = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, (button_icon_or_image === 'image') ?
        React.createElement(OSTooltip, { label: !is_activated ? t('Menu.sankeyOSPDisabled') : '' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.img_src')),
                React.createElement(Box, { as: 'span', layerStyle: 'options_2cols' },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_left', onClick: () => {
                            if (_load_image.current) {
                                _load_image.current.name = '';
                                _load_image.current.click();
                            }
                        } },
                        React.createElement(FaFileImport, null)),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', onClick: () => {
                            Object
                                .values(data_plus.nodes)
                                .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                .forEach(n => n.image_src = '');
                            node_function.reDrawIllustration(multi_selected_nodes.current);
                            setForceUpdate(!forceUpdate);
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faDeleteLeft }))),
                React.createElement(Input, { ref: _load_image, style: { display: 'none' }, accept: 'image/*', type: "file", disabled: !is_activated, onChange: (evt) => {
                        const files = evt.target.files;
                        const reader = new FileReader();
                        reader.onload = (() => {
                            return (e) => {
                                const resultat = e.target.result;
                                const res = resultat === null || resultat === void 0 ? void 0 : resultat.toString().replaceAll('=', '');
                                Object
                                    .values(data_plus.nodes)
                                    .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                    .forEach(n => n.image_src = res);
                                node_function.reDrawIllustration(multi_selected_nodes.current);
                                setForceUpdate(!forceUpdate);
                            };
                        })();
                        reader.readAsDataURL(files[0]);
                    } })))
        : React.createElement(React.Fragment, null));
    // Content of the tab that change depending on the illustration we want to make
    const content_tab = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isIndeterminate: isAllNodeVisible[1], isChecked: isAllNodeVisible[0], onChange: (evt) => {
                Object.values(data.nodes)
                    .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                    .forEach(d => AssignNodeValueToCorrectVar(d, 'shape_visible', evt.target.checked, false));
                node_function.RedrawNodes(multi_selected_nodes.current);
                ComponentUpdater.updateComponentMenuConfigNode.current();
                ComponentUpdater.updateComponentMenuConfigNodeAppearence.current();
                ComponentUpdater.updateComponenSaveInCache.current(false);
                setForceUpdate(!forceUpdate);
            } },
            React.createElement(OSTooltip, { label: t('Noeud.apparence.tooltips.Visibilité') }, t('Noeud.apparence.Visibilité')),
            IsNodeDisplayingValueLocal(multi_selected_nodes, 'shape_visible', false) ?
                TooltipValueSurcharge('node_plus_var', t) :
                React.createElement(React.Fragment, null)),
        React.createElement(OSTooltip, { label: !is_activated ? t('Menu.sankeyOSPDisabled') : '' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.illustration_type')),
                React.createElement(Box, { as: 'span', layerStyle: 'options_3cols' },
                    React.createElement(Button, { variant: button_icon_or_image !== 'none' ?
                            'menuconfigpanel_option_button_left' :
                            'menuconfigpanel_option_button_activated_left', isDisabled: !is_activated, onClick: () => {
                            Object
                                .values(data_plus.nodes)
                                .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                .forEach(d => {
                                d.iconVisible = false;
                                d.is_image = false;
                            });
                            node_function.reDrawIllustration(multi_selected_nodes.current);
                            setForceUpdate(!forceUpdate);
                        } },
                        React.createElement(FaEyeSlash, null)),
                    React.createElement(Button, { variant: button_icon_or_image !== 'icon' ?
                            'menuconfigpanel_option_button_center' :
                            'menuconfigpanel_option_button_activated_center', isDisabled: !is_activated, onClick: () => {
                            Object
                                .values(data_plus.nodes)
                                .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                .forEach(d => {
                                d.is_image = false;
                                d.iconVisible = true;
                            });
                            node_function.reDrawIllustration(multi_selected_nodes.current);
                            setForceUpdate(!forceUpdate);
                        } }, t('Noeud.icon.icon')),
                    React.createElement(Button, { variant: button_icon_or_image !== 'image' ?
                            'menuconfigpanel_option_button_right' :
                            'menuconfigpanel_option_button_activated_right', isDisabled: !is_activated, onClick: () => {
                            Object
                                .values(data_plus.nodes)
                                .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                .forEach(d => {
                                d.is_image = true;
                                d.iconVisible = false;
                            });
                            node_function.reDrawIllustration(multi_selected_nodes.current);
                            setForceUpdate(!forceUpdate);
                        } }, "Image")))),
        button_icon_or_image === 'icon' ?
            content_icon :
            button_icon_or_image === 'image' ?
                content_image :
                React.createElement(React.Fragment, null));
    if (menu_for_modal && !show_menu_node_icon) {
        return [React.createElement(React.Fragment, null)];
    }
    if (menu_for_modal && show_menu_node_icon) {
        return [content_tab];
    }
    return React.createElement(TabPanel, null, content_tab);
};
export const OSPHyperLink = ({ t, data, multi_selected_nodes, is_activated, node_function }) => {
    const multi_selected_nodes_plus = multi_selected_nodes;
    const hasHyperLink = () => {
        var _a, _b;
        let visible = '';
        visible = (_b = (_a = multi_selected_nodes_plus.current[0]) === null || _a === void 0 ? void 0 : _a.hyperlink) !== null && _b !== void 0 ? _b : '';
        return visible;
    };
    const node_hyperlink = hasHyperLink();
    const data_plus = data;
    const content_image_tab = multi_selected_nodes.current.length > 0 ?
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
            React.createElement(OSTooltip, { label: !is_activated ? t('Menu.sankeyOSPDisabled') : '' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.HL')),
                    React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                        React.createElement(Input, { placeholder: node_hyperlink, onChange: (evt) => {
                                Object
                                    .values(data_plus.nodes)
                                    .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                                    .forEach(d => d.hyperlink = evt.target.value);
                                node_function.reDrawOSPNodeEvent(multi_selected_nodes.current);
                            } })))),
            React.createElement(OSTooltip, { label: !is_activated ? t('Menu.sankeyOSPDisabled') : '' },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.open_HL')),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                            window.open(node_hyperlink);
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faUpRightFromSquare }))))) :
        React.createElement(React.Fragment, null);
    return React.createElement(TabPanel, null, content_image_tab);
};
const node_mouse_click = (applicationData, applicationState, uiElementsRef, animating, event, d, accept_simple_click, GetLinkValue, ComponentUpdater) => {
    const { data, display_nodes } = applicationData;
    const sankeyTooltip = d3.select('.sankey-tooltip');
    // shift + click on a node launch a animation that show all sub path from this node
    if (event.shiftKey) {
        event.preventDefault();
        animating.current = true;
        // Animation des flux du Sankey
        sankeyTooltip.style('opacity', 0);
        // on donne ici un style temporaire, les parametres initiaux restent dans le attr que l'on pourra récupérer plus tard pour la remise en état du sankey
        d3.select(' .opensankey #svg').selectAll('.arrow').attr('fill', '#dddddd');
        d3.select(' .opensankey #svg').selectAll('.link').style('stroke', '#dddddd');
        d3.select(' .opensankey #svg').selectAll('.node').style('fill', '#dddddd');
        d3.select(' .opensankey #svg').selectAll('.link_value').style('display', 'none');
        const nodeDisplay = [d.idNode];
        const node_visible = Object.values(display_nodes).map(n => n.idNode);
        branchAnimate(data, d, nodeDisplay, node_visible, GetLinkValue);
        let time_to_animate = 500;
        //calcul la profondeur max de nouveau flux (le nombre de nouveau flux consecutif ) afin de calculer le temps qu'il faut avant de changer la variable set_view
        const horizontal_indexes_per_nodes_ids = {};
        const possible_recycling_links_ids = [];
        computeHorizontalIndex(d, 0, Object.keys(display_nodes), [], possible_recycling_links_ids, horizontal_indexes_per_nodes_ids, data.links, data.nodes);
        // Get longest path to animate possible (number of path before we get to a node without output link  )
        // so we can determinate a timeout before reseting the sankey
        let nb_animation = Object.values(horizontal_indexes_per_nodes_ids).reduce((a, b) => Math.max(a, b), -Infinity);
        nb_animation = (nb_animation !== undefined) ? nb_animation : 0;
        time_to_animate += nb_animation * 2000;
        setTimeout(function () {
            animating.current = false;
            applicationData.set_data(Object.assign({}, data));
        }, time_to_animate);
    }
    else if (window.SankeyToolsStatic === true) {
        const n = d;
        if (n.hyperlink !== undefined && n.hyperlink !== '') {
            window.open(n.hyperlink);
        }
    }
    else if (!window.SankeyToolsStatic && event.altKey) {
        const n = d;
        if (n.hyperlink !== undefined && n.hyperlink !== '') {
            window.open(n.hyperlink);
        }
    }
    else {
        SimpleGNodeClick(uiElementsRef, applicationState, event, d, accept_simple_click, ComponentUpdater);
    }
};
const branchAnimate = (data, nodeData, nodeDisplay, node_visible, GetLinkValue) => {
    const data_plus = data;
    // Permet la progation de l'animation sur l'ensemble du Sankey
    const nodeStart = nodeData.idNode;
    // on pourrait aussi evnetuellement faire un clone des noeuds
    d3.select(' .opensankey #shape_' + nodeData.idNode).style('fill', d3.select(' .opensankey #shape_' + nodeData.idNode).attr('fill'));
    d3.select(' .opensankey #text_' + nodeData.idNode).style('fill', d3.select(' .opensankey #shape_' + nodeData.idNode).attr('fill'));
    const glinks = d3.select(' .opensankey #svg').selectAll('.gg_links')
        .filter(function (d) {
        return d.idSource === nodeStart;
    });
    // On fait une copie du link pour son animation, le flux originel reste en claire et la copie 'remplie' le path
    const tmpLinks = glinks.clone(true).raise();
    tmpLinks.selectAll('.link')
        .each(function () {
        const totalLength = this.getTotalLength();
        d3.select(this)
            .attr('stroke-dasharray', totalLength + ' ' + totalLength)
            .attr('stroke-dashoffset', totalLength)
            .style('stroke', function () {
            // on recupere les paramêtres initiaux du stroke
            return d3.select(this).attr('stroke');
        });
    })
        .transition()
        .duration(2000)
        .attr('stroke-dashoffset', 0)
        .on('end', function () {
        const idLink = d3.select(this).attr('id').replace('path_', '');
        const idTarget = data.links[idLink].idTarget;
        // Modification des arrows après l'animation
        const arrow = d3.selectAll(' .opensankey #path_' + idLink + '_arrow');
        if (arrow !== undefined && arrow != null) {
            const colorTarget = (ReturnValueNode(data, data.nodes[idTarget], 'shape_visible')) ? NodeColor(data.nodes[idTarget], data) : ((data_plus.nodes[idTarget].iconVisible) ? data_plus.nodes[idTarget].iconColor : 'grey');
            // const t=(data.links[idLink].gradient && data.colorMap=='no_colormap')?colorTarget:d3.select(this).attr('stroke')
            const l_grad = OSPReturnValueLink(data_plus, data_plus.links[idLink], 'gradient');
            const t = (l_grad) ? colorTarget : LinkStrokeOSTyped(data.links[idLink], data, GetLinkValue);
            if (t) {
                arrow.attr('fill', t);
                arrow.attr('opacity', 0.85);
            }
        }
        // reaffichage des link value après l'animation
        d3.select(this.parentNode).select('.link_value')
            .style('display', 'inline');
        //Propagration de l'animation sur les flux sortant du target_node
        // on teste si le noeud est déjà passé cela permet de régler le problème des links à 'recycling'
        if (!nodeDisplay.includes(idTarget)) {
            nodeDisplay.push(idTarget);
            let max = 0;
            const tmp = direct_son_as_distant_sibling(data, nodeData, data_plus.nodes[idTarget], 0, [idLink], node_visible);
            max = (tmp > max) ? tmp : max;
            setTimeout(() => {
                branchAnimate(data, data_plus.nodes[idTarget], nodeDisplay, node_visible, GetLinkValue);
            }, max * 2000);
        }
    });
};
const direct_son_as_distant_sibling = (data, n, target, deep, link_to_avoid, display_nodes_id) => {
    //Cherche à savoir si un noeud qui recoit directement le flux de n ai aussi un path inderectement vers ce meme noeud
    //exemple : n0 -> n1  et n0 -> n2 -> n1
    //fonction utilisé pour que le noeud qui recoit le flux direct attend les chemin indirect avant de lancer les animations suivantes
    const next_link = n.outputLinksId.filter(f => (!ReturnValueLink(data, data.links[f], 'recycling') && !Object.values(link_to_avoid).includes(f) && display_nodes_id.includes(data.links[f].idTarget)));
    let max = 0;
    const data_plus = data;
    if (n.idNode === target.idNode) {
        return deep - 1;
    }
    else if (next_link.length > 0) {
        next_link.map(id => {
            const next_node = data_plus.nodes[data.links[id].idTarget];
            //utilise array.concat pour ne pas modifier le tableau original (contrairement a .push)
            const to_avoid = link_to_avoid.concat([id]);
            const tmp = direct_son_as_distant_sibling(data, next_node, target, deep + 1, to_avoid, display_nodes_id);
            max = (tmp > max) ? tmp : max;
        });
    }
    return max;
};
export const OSPNodeClickEvent = (applicationData, applicationState, uiElementsRef, animating, accept_simple_click, GetLinkValue, ComponentUpdater, nodes_to_update) => {
    d3.selectAll(' .opensankey .ggg_nodes').filter(n => nodes_to_update.length > 0 ? nodes_to_update.includes(n) : true)
        .on('click', (event, d) => {
        // Apply some style change to element before starting the animation
        node_mouse_click(applicationData, applicationState, uiElementsRef, animating, event, d, accept_simple_click, GetLinkValue, ComponentUpdater);
    });
};
export const node_icon_fill_color = (data, n) => {
    if (n.colorTag in n.tags && n.colorTag in n.tags && n.colorParameter === 'groupTag') {
        const selected_tag = n.tags[n.colorTag][0];
        const tag = data.nodeTags[n.colorTag].tags[selected_tag];
        if (tag && !ReturnValueNode(data, n, 'shape_visible') && !n.iconColorSustainable) {
            return tag.color;
        }
    }
    return n.iconColor;
};
export const node_icon_path = (data, n) => {
    const icon = data.icon_catalog[n.iconName];
    if (icon !== undefined && icon !== null) {
        return icon;
    }
    return '';
};
export const OSPDrawNodesIllustration = (data, node_to_update, applicationState, NodeTooltipsContent, GetLinkValue, trad) => {
    const { ref_getter_mode_selection } = applicationState;
    const local_displayed_node = {};
    node_to_update.forEach(n => local_displayed_node[n.idNode] = n);
    const node_mouse_over = (data, t, event, d) => {
        d3.select(t).attr('cursor', (ref_getter_mode_selection.current === 's') ? 'pointer' : 'unset');
        if (event.shiftKey) {
            const sankeyTooltip = d3.select('.sankey-tooltip');
            sankeyTooltip
                .style('opacity', 1)
                .html(NodeTooltipsContent(data, local_displayed_node, d, GetLinkValue, trad));
        }
    };
    const node_mouse_move = (event) => {
        if (event.shiftKey) {
            const sankeyTooltip = d3.select('.sankey-tooltip');
            const h_tooltip = Number(sankeyTooltip.style('height').replace('px', ''));
            let pos_tooltip_y = event.clientY;
            const size_browser = window.innerHeight;
            pos_tooltip_y = ((h_tooltip + pos_tooltip_y) > size_browser) ? event.pageY + (size_browser - (pos_tooltip_y + h_tooltip)) - 5 : event.pageY;
            const w_tooltip = Number(sankeyTooltip.style('width').replace('px', ''));
            let pos_tooltip_x = event.clientX;
            const size_browser_w = window.innerWidth;
            pos_tooltip_x = ((w_tooltip + pos_tooltip_x) > size_browser_w) ? event.pageX - w_tooltip - 30 : event.pageX + 30;
            sankeyTooltip
                .style('top', pos_tooltip_y + 'px')
                .style('left', pos_tooltip_x + 'px');
        }
    };
    const add_nodes_icon = () => {
        //----------------ICON-----------------
        // Add icon to node (if there is one associated to it)
        // then apply selected parameter
        const sankeyTooltip = d3.select('div.sankey-tooltip');
        const ggg_nodes = d3.selectAll('.ggg_nodes').filter(n => node_to_update.length > 0 ? node_to_update.includes(n) : true);
        ggg_nodes.selectAll('.icon_node').remove();
        ggg_nodes.selectAll('.image_node').remove();
        ggg_nodes
            .filter(d => d.iconName !== 'none' && d.iconVisible)
            .append('svg')
            .attr('id', n => 'icon_node_' + n.idNode)
            .attr('class', 'icon_node')
            .attr('viewBox', d => d.iconViewBox ? d.iconViewBox : '0 0 1000 1000')
            .attr('height', n => +d3.select(' .opensankey #shape_' + n.idNode).attr('height'))
            .attr('width', n => +d3.select(' .opensankey #shape_' + n.idNode).attr('width'))
            .attr('x', 0)
            .append('g')
            .append('path')
            .on('mouseover', function (event, d) {
            node_mouse_over(data, this, event, d);
        })
            .on('mousemove', function (event) {
            node_mouse_move(event);
        })
            .on('mouseout', function () {
            sankeyTooltip.style('opacity', 0);
        })
            .style('fill', n => node_icon_fill_color(data, n))
            .attr('d', n => node_icon_path(data, n));
    };
    const add_nodes_image = () => {
        //----------------ICON-----------------
        // Add icon to node (if there is one associated to it)
        // then apply selected parameter
        const sankeyTooltip = d3.select('div.sankey-tooltip');
        const ggg_nodes = d3.selectAll('.ggg_nodes').filter(n => node_to_update.length > 0 ? node_to_update.includes(n) : true);
        ggg_nodes.filter(d => d.is_image).selectAll('.icon_node').remove();
        ggg_nodes.filter(d => d.is_image).selectAll('.image_node').remove();
        const images = ggg_nodes
            .filter(d => d.is_image)
            .append('image')
            .attr('id', n => 'image_node_' + n.idNode)
            .attr('class', 'image_node')
            .attr('href', n => n.image_src)
            .attr('height', n => +d3.select(' .opensankey #shape_' + n.idNode).attr('height'))
            .attr('width', n => +d3.select(' .opensankey #shape_' + n.idNode).attr('width'));
        // images.attr('height', 150 /* n => Math.min(150,+d3.select(' .opensankey #shape_' + n.idNode).attr('height')*0.8 )*/)
        // images.filter(n => n.outputLinksId.length > 0).attr('width',n=> +d3.select(' .opensankey #shape_' + n.idNode).attr('width') )
        // images.filter(n => n.outputLinksId.length == 0).attr('x','10px')
        // images.attr('y', n => (+d3.select(' .opensankey #shape_' + n.idNode).attr('height') - Math.min(150,+d3.select(' .opensankey #shape_' + n.idNode).attr('height')*0.8 ))/2 )
        images.on('mouseover', function (event, d) {
            node_mouse_over(data, this, event, d);
        });
        images.on('mousemove', function (event) {
            node_mouse_move(event);
        });
        images.on('mouseout', function () {
            sankeyTooltip.style('opacity', 0);
        });
    };
    add_nodes_icon();
    add_nodes_image();
};
export const ContextNodeIcon = (contextMenu, dict_hook_ref_setter_show_dialog_components, t) => {
    const icon_open_modal = React.createElement(FontAwesomeIcon, { style: { float: 'right' }, icon: faUpRightFromSquare });
    return React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
            dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_node_icon.current(true);
            contextMenu.ref_setter_contextualised_node.current(undefined);
        } },
        t('Noeud.icon.icon'),
        icon_open_modal);
};
/**
 * Shift all elements (ZDT/Nodes) not selected to the opposing direction of the event
 *
 * @param {((SankeyNode|OSPLabel)[])} out_of_zone_item
 * @param {{ dx: number; dy: number,x:number,y:number }} event
 * @param {(SankeyNode|OSPLabel)} dragged
 * @param {SankeyData} data
 * @param {{current:SankeyNode[]}} multi_selected_nodes
 * @param {{current:OSPLabel[]}} multi_selected_label
 */
export const OpposingDragElementsPlus = (out_of_zone_item, event, dragged, applicationData, applicationState) => {
    const { data } = applicationData;
    const { multi_selected_label } = applicationState;
    OpposingDragElements(out_of_zone_item, event, dragged, applicationData, applicationState);
    if ((out_of_zone_item[0].x <= 0 && event.x < 0) || (out_of_zone_item[0].x <= 0 && event.dx < 0)) {
        // Shift not selected zdt to opposing direction
        Object.values(data.labels).forEach(lb => {
            if (!multi_selected_label.current.includes(lb)) {
                const new_pos_x = lb.x - event.dx;
                lb.x = new_pos_x;
                d3.select(' .opensankey #' + lb.idLabel).attr('transform', 'translate(' + lb.x + ',' + lb.y + ')');
                // shift handles of non dragged zdt
                d3.selectAll(' .opensankey #g_label_handles #gg_zdt_handles_' + lb.idLabel + ' .zdt_handles').nodes().forEach(g_zdt_h => {
                    const x = +d3.select(g_zdt_h).attr('x');
                    d3.select(g_zdt_h).attr('x', x - event.dx);
                });
            }
        });
    }
    if ((out_of_zone_item[0].y <= 0 && event.y < 0) || (out_of_zone_item[0].y <= 0 && event.dy < 0)) {
        // Shift zdt to opposing direction
        Object.values(data.labels).forEach(lb => {
            if (!multi_selected_label.current.includes(lb)) {
                const new_pos_y = lb.y - event.dy;
                lb.y = new_pos_y;
                d3.select(' .opensankey #' + lb.idLabel).attr('transform', 'translate(' + lb.x + ',' + lb.y + ')');
                // shift handles of non dragged zdt
                d3.selectAll(' .opensankey #g_label_handles #gg_zdt_handles_' + lb.idLabel + ' .zdt_handles').nodes().forEach(g_zdt_h => {
                    const y = +d3.select(g_zdt_h).attr('y');
                    d3.select(g_zdt_h).attr('y', y - event.dy);
                });
            }
        });
    }
};
export const OSPNodeDragEvent = (applicaTionData, applicationState, applicationContext, alt_key_pressed, ComponentUpdater, node_function, link_function, applicationDraw) => {
    const { data } = applicaTionData;
    const { ref_getter_mode_selection } = applicationState;
    const inv_scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, data.user_scale]);
    const scale = d3.scaleLinear()
        .range([0, 100])
        .domain([0, data.user_scale]);
    if (ref_getter_mode_selection.current === 's' && window.SankeyToolsStatic !== true) {
        d3.selectAll('.ggg_nodes').call(OSPDragGNodeEvent(applicaTionData, applicationState, applicationContext, alt_key_pressed, scale, inv_scale, ComponentUpdater, node_function, link_function, applicationDraw));
    }
    d3.select('.opensankey #svg').call(d3.drag()
        .subject(Object)
        .filter(evt => {
        evt.stopPropagation();
        evt.preventDefault();
        return d3.select(evt.target).attr('id') === 'svg' && evt.which === 2;
    })
        .on('start', () => SvgDragMiddleMouseStart())
        .on('drag', evt => {
        SvgDragMiddleMouseMove(evt, data);
        // Drag ZDT too
        Object.values(data.labels).forEach(lb => {
            const new_pos_x = lb.x + evt.dx;
            const new_pos_y = lb.y + evt.dy;
            lb.x = new_pos_x;
            lb.y = new_pos_y;
            d3.select(' .opensankey #' + lb.idLabel).attr('transform', 'translate(' + lb.x + ',' + lb.y + ')');
        });
    })
        .on('end', function (_, n) {
        setTimeout(() => {
            const node = n;
            // update all nodes connected to dragged node & all links connected to these nodes
            const node_to_update = [node];
            node.outputLinksId.forEach(lid => node_to_update.push(data.nodes[data.links[lid].idTarget]));
            node.inputLinksId.forEach(lid => node_to_update.push(data.nodes[data.links[lid].idSource]));
            let link_to_update = [];
            node_to_update.forEach(node => {
                link_to_update = link_to_update.concat(node.outputLinksId.map(lid => data.links[lid]));
                link_to_update = link_to_update.concat(node.inputLinksId.map(lid => data.links[lid]));
            });
            node_function.RedrawNodes(node_to_update);
            link_function.RedrawLinks(link_to_update);
            actualizeDrawAreaFrame(applicaTionData, applicationDraw.GetSankeyMinWidthAndHeight);
        }, 100);
    }));
};
const OSPDragGNodeEvent = (applicationData, applicationState, applicationContext, alt_key_pressed, scale, inv_scale, ComponentUpdater, node_function, link_function, applicationDraw) => {
    const { ref_getter_mode_selection } = applicationState;
    const node_visible = [];
    return d3.drag()
        .subject(Object)
        .on('start', () => {
        if (ref_getter_mode_selection.current === 's' && window.SankeyToolsStatic !== true) {
            d3.selectAll('.node_shape').nodes().forEach(element => {
                node_visible.push(d3.select(element).attr('id'));
            });
            hideLinkOnDragElement(applicationData);
        }
    })
        .on('drag', function (event, node) {
        if (ref_getter_mode_selection.current === 's') {
            if (d3.select(event.subject.sourceEvent.target).node().tagName === 'tspan' && alt_key_pressed && !(window.SankeyToolsStatic ? window.SankeyToolsStatic : false)) {
                drag_node_text(node, event);
            }
            else {
                OSPDragNodes(applicationData, applicationState, applicationContext, node, event, applicationDraw.GetSankeyMinWidthAndHeight, scale, inv_scale, node_visible, ComponentUpdater, link_function);
            }
        }
    })
        .on('end', () => {
        if (ref_getter_mode_selection.current === 's') {
            setTimeout(() => {
                if (d3.select(document.activeElement).attr('class') !== 'input_label') {
                    node_function.RedrawNodes(Object.values(applicationData.display_nodes));
                    link_function.RedrawLinks(Object.values(applicationData.display_links));
                }
                applicationDraw.resizeCanvas();
            }, 100);
        }
    });
};
const OSPDragNodes = (applicationData, applicationState, applicationContext, node, event, GetSankeyMinWidthAndHeight, scale, inv_scale, node_visible, ComponentUpdater, link_function) => {
    RemoveAnimate();
    const { data, } = applicationData;
    const { multi_selected_nodes } = applicationState;
    // Cherche si des element seront hors zone si on les drag
    // Si c'est le cas, pousse les éléments qui ne sont pas sélectionnés dans la direction opposé
    const out_of_zone_item = OSPReturnOutOfBoundElements(node, data, event, multi_selected_nodes, node_visible);
    // Pousse les element non sélectionnés dans la direction opposé
    if (out_of_zone_item.length > 0) {
        OpposingDragElementsPlus(out_of_zone_item, event, node, applicationData, applicationState);
    }
    OSPDragElements(applicationData, applicationState, applicationContext, node, event, GetSankeyMinWidthAndHeight, scale, inv_scale, ComponentUpdater, link_function);
};
export const OSPDragElements = (applicationData, applicationState, applicationContext, dragged, event, GetSankeyMinWidthAndHeight, scale, inv_scale, ComponentUpdater, link_function) => {
    const { multi_selected_label } = applicationState;
    DragElements(dragged, applicationData, applicationState, applicationContext, event, GetSankeyMinWidthAndHeight, scale, inv_scale, ComponentUpdater, link_function);
    // Drag zdt too
    multi_selected_label.current.map(l => {
        const new_pos_x = l.x + event.dx;
        const new_pos_y = l.y + event.dy;
        l.x = (new_pos_x >= 0) ? new_pos_x : 0;
        l.y = (new_pos_y > 0) ? new_pos_y : 0;
        const pos_zdt = sizeOfZdtInDrawArea(l);
        const margin = applicationData.data.grid_square_size * 2;
        if ((pos_zdt[0] + margin) > applicationData.data.width) {
            const svgSankey = d3.select('.opensankey #svg');
            svgSankey.style('width', (pos_zdt[0] + margin) + 'px');
        }
        if ((pos_zdt[1] + margin) > applicationData.data.height) {
            const svgSankey = d3.select('.opensankey #svg');
            svgSankey.style('height', (pos_zdt[1] + margin) + 'px');
        }
        d3.select(' .opensankey #' + l.idLabel).attr('transform', 'translate(' + l.x + ',' + l.y + ')');
        d3.selectAll('.opensankey #gg_zdt_handles_' + l.idLabel + ' .zdt_handles').nodes().forEach(el => {
            const new_cx = Number(d3.select(el).attr('x'));
            const new_cy = Number(d3.select(el).attr('y'));
            d3.select(el).attr('x', (l.x <= 0) ? new_cx : (new_cx + event.dx));
            d3.select(el).attr('y', (l.y <= 0) ? new_cy : (new_cy + event.dy));
        });
    });
};
export const OSPReturnOutOfBoundElements = (dragged, data, event, multi_selected_nodes, node_visible) => {
    // Cherche si des noeuds seront hors zone si on les drag
    // Si c'est le cas, pousse les éléments qui ne sont pas sélectionnés dans la direction opposé
    const out_of_zone_item = ReturnOutOfBoundElement(dragged, data, event, multi_selected_nodes, node_visible);
    Object.values(data.labels).filter(lb => {
        return (lb.x <= 0 && event.dx < 0) || (lb.y <= 0 && event.dy < 0) || (lb.x <= 0 && event.x < 0) || (lb.y <= 0 && event.y < 0);
    }).forEach(lb => out_of_zone_item.push(lb));
    return out_of_zone_item;
};
export const scale = d3.scaleLinear()
    .domain([0, 100])
    .range([0, 100]);
export const inv_scale = d3.scaleLinear()
    .domain([0, 100])
    .range([0, 100]);
export const sizeOfZdtInDrawArea = (n) => {
    return [(n.x + n.label_width), (n.y + n.label_height)];
};
