import * as d3 from 'd3';
import { GetLinkValue, NodeColor, NodeHasDisplayedLevel, NodeHasDisplayedTags, ReturnLocalNodeValue, ReturnValueNode } from '../configmenus/SankeyUtils';
import { SetNodeHeight, nodeTransform, NodeStrokeWidth, PathNodeArrowShape, } from './SankeyDrawFunction';
import { SimpleGNodeClick } from './SankeyDrawEventFunction';
import { EventOnMouseUpAddNodesAndLink } from './SankeyDrawEventFunction';
import { EventNodeContextMenu } from './SankeyDrawEventFunction';
import { DragGNodeEvent } from './SankeyDragNodes';
import { RedrawNodesLabel } from './SankeyDrawNodesLabel';
export const DrawAllNodes = (contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, GetSankeyMinWidthAndHeight, resizeCanvas) => {
    const { display_nodes } = applicationData;
    const { multi_selected_nodes } = applicationState;
    const { GetLinkValue } = link_function;
    const { t } = applicationContext;
    // The majority of data used to design the node are located in data['nodes']
    // Or if you want information about the type of these variable, you can find them in file types.tsx
    d3.selectAll(' .opensankey .gg_nodes').remove();
    drawAddNodes(contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, Object.values(display_nodes), GetSankeyMinWidthAndHeight, resizeCanvas);
    updateDrawNodeShape(applicationData, link_function, multi_selected_nodes, Object.values(display_nodes));
    RedrawNodesLabel(applicationData, Object.values(display_nodes), GetLinkValue, link_function.LinkText, t, node_function);
};
/**
 * Add/update nodes event
 */
export const AddDrawNodesEvent = (contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, GetSankeyMinWidthAndHeight, resizeCanvas) => {
    const { LinkText, GetLinkValue } = link_function;
    const { data, display_nodes } = applicationData;
    const { ref_getter_mode_selection, multi_selected_nodes, first_selected_node } = applicationState;
    const inv_scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, data.user_scale]);
    const scale = d3.scaleLinear()
        .range([0, 100])
        .domain([0, data.user_scale]);
    const ggg_nodes = d3.selectAll('.ggg_nodes');
    // const filtered_gggnodes = ggg_nodes.filter(
    //   n=> multi_selected_nodes.current.length>0 ? multi_selected_nodes.current.includes(n) : true
    // )
    if (!(window.SankeyToolsStatic ? window.SankeyToolsStatic : false)) {
        // Add event listener to click 
        // When we Ctrl + click a node, it select it and open a menu 
        const DoubleGNodeClick = (event, d) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            accept_simple_click.current = false;
            const label_x = (_b = (_a = document.getElementById('text_' + d.idNode)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().x) !== null && _b !== void 0 ? _b : 0;
            const label_y = (_d = (_c = document.getElementById('text_' + d.idNode)) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().y) !== null && _d !== void 0 ? _d : 0;
            const node_x = (_f = (_e = document.getElementById('shape_' + d.idNode)) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect().x) !== null && _f !== void 0 ? _f : 0;
            const node_y = (_h = (_g = document.getElementById('shape_' + d.idNode)) === null || _g === void 0 ? void 0 : _g.getBoundingClientRect().y) !== null && _h !== void 0 ? _h : 0;
            d3.select('#fo_input_label_' + d.idNode).style('display', 'inline-block');
            d3.select('#fo_input_label_' + d.idNode).attr('x', (label_x - node_x)).attr('y', label_y - node_y);
            d3.select('#text_' + d.idNode).style('display', 'none');
            (_j = document.getElementById('input_label_' + d.idNode)) === null || _j === void 0 ? void 0 : _j.focus();
            setTimeout(() => {
                accept_simple_click.current = true;
            }, 200);
        };
        ggg_nodes
            .filter(() => !(window.SankeyToolsStatic ? window.SankeyToolsStatic : false))
            .on('click', (event, d) => SimpleGNodeClick(uiElementsRef, applicationState, event, d, accept_simple_click, ComponentUpdater))
            .on('dblclick', (event, d) => DoubleGNodeClick(event, d));
        if (ref_getter_mode_selection.current == 'ln') {
            ggg_nodes.on('mousedown', function (event, d) {
                if (!event.ctrlKey && !event.metaKey) {
                    first_selected_node.current = d;
                }
            })
                .on('mouseup', (event, d) => EventOnMouseUpAddNodesAndLink(event, d, applicationData, applicationState, uiElementsRef, applicationContext, ComponentUpdater, link_function, node_function));
        }
        // allow nodes to be dragged
        if (ref_getter_mode_selection.current == 's' && window.SankeyToolsStatic !== true) {
            ggg_nodes.call(DragGNodeEvent(applicationData, applicationState, applicationContext, alt_key_pressed, LinkText, GetLinkValue, scale, inv_scale, ComponentUpdater, node_function, link_function, GetSankeyMinWidthAndHeight, resizeCanvas));
        }
    }
    // ggg_nodes.on('contextmenu', (ev, n) => EventNodeContextMenu(ev,n,data,set_agregation_node,set_is_agregation,set_show_agregation,set_data) )
    ggg_nodes.on('contextmenu', (ev, n) => {
        //if(!window.SankeyToolsStatic){
        // if the right mouse button is clicked we switch to selection mode
        // applicationState.ref_setter_mode_selection.current('s')
        // applicationState.ref_getter_mode_selection.current = 's'
        // d3.select(' .opensankey #svg').attr('class','mode_selection')
        return EventNodeContextMenu(ev, n, contextMenu, multi_selected_nodes);
        //}
    });
    // Add tooltip when mouse hover the <g> element that contains shape/label/icon (everything that compose a node)
    const gg_nodes = d3.selectAll(' .opensankey .gg_nodes');
    const filtered_gg_nodes = gg_nodes.filter(n => multi_selected_nodes.current.length > 0 ? multi_selected_nodes.current.includes(n) : true);
    // Gestion de la tooltip
    filtered_gg_nodes.on('mouseover', function (event, d) {
        d3.select(this).attr('cursor', (ref_getter_mode_selection.current == 's') ? 'pointer' : 'unset');
        if ((event.shiftKey)) {
            const sankeyTooltip = d3.select('.sankey-tooltip');
            sankeyTooltip
                .style('opacity', 1)
                .html(node_function.NodeTooltipsContent(data, display_nodes, d, GetLinkValue, applicationContext.t));
        }
    });
    filtered_gg_nodes.on('mousemove', function (event) {
        // Triggered when the mouse move over the node
        if ((event.shiftKey)) {
            const sankeyTooltip = d3.select('.sankey-tooltip');
            const h_tooltip = Number(sankeyTooltip.style('height').replace('px', ''));
            let pos_tooltip_y = event.clientY;
            const size_browser = window.innerHeight;
            pos_tooltip_y = ((h_tooltip + pos_tooltip_y) > size_browser) ? event.pageY + (size_browser - (pos_tooltip_y + h_tooltip)) - 5 : event.pageY;
            const w_tooltip = Number(sankeyTooltip.style('width').replace('px', ''));
            let pos_tooltip_x = event.clientX;
            const size_browser_w = window.innerWidth;
            pos_tooltip_x = ((w_tooltip + pos_tooltip_x) > size_browser_w) ? event.pageX - w_tooltip - 30 : event.pageX + 30;
            sankeyTooltip
                .style('top', pos_tooltip_y + 'px')
                .style('left', pos_tooltip_x + 'px');
        }
    });
    filtered_gg_nodes.on('mouseout', function () {
        const sankeyTooltip = d3.select('.sankey-tooltip');
        sankeyTooltip.style('opacity', 0);
    });
};
/**
 * Update visual elements linked to the shape of nodes
 */
export const updateDrawNodeShape = (applicationData, link_function, multi_selected_nodes, node_to_update) => {
    const { data } = applicationData;
    const { GetLinkValue } = link_function;
    const inv_scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, data.user_scale]);
    const scale = d3.scaleLinear()
        .range([0, 100])
        .domain([0, data.user_scale]);
    const ggg_nodes = d3.selectAll('.ggg_nodes');
    //ggg_nodes.sort((d1,d2)=>d3.ascending(d1.v,d2.v))
    const filtered_gggnodes = ggg_nodes.filter(n => node_to_update.length > 0 ? node_to_update.includes(n) : true);
    //filtered_gggnodes.sort((d1,d2)=>d3.ascending(d1.v,d2.v))
    node_to_update = [];
    filtered_gggnodes.each(d => {
        node_to_update.push(d);
    });
    // filtered_gggnodes.selectAll('rect').remove()
    // filtered_gggnodes.selectAll('ellipse').remove()
    // filtered_gggnodes.selectAll('path').remove()
    filtered_gggnodes.selectAll('*').remove();
    filtered_gggnodes.attr('transform', d => nodeTransform(applicationData, d, link_function, false));
    filtered_gggnodes
        .filter(d => ReturnValueNode(data, d, 'shape') === 'rect')
        .append('rect')
        .classed('node', true)
        .classed('node_shape', true);
    filtered_gggnodes
        .filter(d => ReturnValueNode(data, d, 'shape') === 'ellipse')
        .append('ellipse')
        .classed('node', true)
        .classed('node_shape', true)
        .attr('cx', d => ReturnValueNode(data, d, 'node_width') / 2)
        .attr('cy', d => ReturnValueNode(data, d, 'node_height') / 2)
        .attr('rx', d => ReturnValueNode(data, d, 'node_width') / 2)
        .attr('ry', d => ReturnValueNode(data, d, 'node_height') / 2);
    filtered_gggnodes
        .filter(d => ReturnValueNode(data, d, 'shape') === 'arrow')
        .append('path')
        .classed('node', true)
        .classed('node_shape', true)
        .attr('d', d => {
        const n_w = ReturnValueNode(data, d, 'node_width');
        const n_h = ReturnValueNode(data, d, 'node_height');
        const k_angle = ReturnValueNode(data, d, 'node_arrow_angle_factor');
        const angle_direction = ReturnValueNode(data, d, 'node_arrow_angle_direction');
        // const path='M0,0L'+n_w*(1-k_angle)+',0L'+n_w+','+n_h/2+'L'+n_w*(1-k_angle)+','+n_h+'L0,'+n_h+'L'+n_w*k_angle+','+n_h/2
        const path = PathNodeArrowShape(n_w, n_h, k_angle, angle_direction);
        return path;
    });
    // Apply node's parameters to each node
    d3.selectAll(' .opensankey .node_shape')
        .attr('id', d => 'shape_' + d.idNode)
        .attr('fill-opacity', d => ReturnValueNode(data, d, 'shape_visible') ? '1' : '0')
        .attr('fill', d => NodeColor(d, data))
        .style('stroke', 'black')
        .style('stroke-width', d => {
        const dd = d;
        return NodeStrokeWidth(dd, multi_selected_nodes);
    });
    node_to_update.forEach(n => {
        SetNodeHeight(n, applicationData, GetLinkValue);
        d3.select(' .opensankey #gg_' + n.idNode)
            .style('display', () => {
            return 'inline';
        });
    });
};
const NodeDisplayed = (data, node) => {
    const has_local_level = ReturnLocalNodeValue(node, 'local_aggregation');
    const local_level = has_local_level !== null && has_local_level !== void 0 ? has_local_level : NodeHasDisplayedLevel(data, node);
    return NodeHasDisplayedTags(data, node) && local_level;
};
// Check if incoming and/or outgoing links have all 0 for value, if that the case we we returne false
// We can short-circuit the function if the variable null_flux is true or the variable is show_structur is 'structure' (doesn't care about links value)
export const HasLinksZero = (data, node) => {
    if ((ReturnValueNode(data, node, 'orphan_node_visible') == true && node.outputLinksId.length == 0 && node.inputLinksId.length == 0)
        || data.show_structure == 'structure' || data.show_structure == 'data') {
        return false;
    }
    else {
        let total_input = 0;
        if (node.inputLinksId.length > 0) {
            const special_data_cast = data;
            for (let i = 0; i < node.inputLinksId.length; i++) {
                const link = data.links[node.inputLinksId[i]];
                if (link === undefined) {
                    //alert('Corruption du diagramme')
                    continue;
                }
                if (!NodeDisplayed(data, data.nodes[link.idSource]) || !NodeDisplayed(data, data.nodes[link.idTarget])) {
                    continue;
                }
                if (data.nodes[link.idSource] && data.nodes[link.idTarget]) {
                    const val = GetLinkValue(data, link.idLink);
                    if (special_data_cast.free_null_link_visible && (val === null || val === void 0 ? void 0 : val.extension.free_mini) !== undefined && val.value == 0) {
                        total_input += 1;
                        continue;
                    }
                    if (val && val.value != undefined) {
                        total_input += val.value;
                    }
                    else {
                        console.log('val is undefined');
                    }
                }
            }
        }
        let total_output = 0;
        if (node.outputLinksId.length > 0) {
            const special_data_cast = data;
            for (let i = 0; i < node.outputLinksId.length; i++) {
                const link = data.links[node.outputLinksId[i]];
                if (link === undefined) {
                    //alert('Corruption du diagramme')
                    continue;
                }
                if (!NodeDisplayed(data, data.nodes[link.idSource]) || !NodeDisplayed(data, data.nodes[link.idTarget])) {
                    continue;
                }
                if (data.nodes[link.idSource] && data.nodes[link.idTarget]) {
                    const val = GetLinkValue(data, link.idLink);
                    if (special_data_cast.free_null_link_visible && (val === null || val === void 0 ? void 0 : val.extension.free_mini) !== undefined && val.value == 0) {
                        total_input += 1;
                        continue;
                    }
                    if (val && val.value != undefined) {
                        total_output += val.value;
                    }
                    else {
                        console.log('val is undefined');
                    }
                }
            }
        }
        return (total_input + total_output) === 0;
    }
};
/**
 * Create <g> elements for each node that we draw that will contain all visual element linked to nodes
 */
export const drawAddNodes = (contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, node_to_draw, GetSankeyMinWidthAndHeight, resizeCanvas) => {
    const { multi_selected_nodes } = applicationState;
    const { data, display_nodes } = applicationData;
    const { t } = applicationContext;
    // const filtered_data = multi_selected_nodes.current.length>0 ? multi_selected_nodes.current : Object.values(display_nodes)
    const columns = {};
    // if (/*!data.parametric_mode*/false) {
    //   columns[0] = Object.values(display_nodes).filter(n=>node_to_draw.includes(n))
    // } else {
    Object.values(display_nodes).forEach(n => {
        if (columns[n.u]) {
            columns[n.u].push(n);
        }
        else {
            columns[n.u] = [n];
        }
    });
    d3.selectAll(' .opensankey #g_nodes g').remove();
    // }
    Object.values(columns).forEach(column => {
        column.sort((n1, n2) => {
            if (n1.v >= 0 || n2.v >= 0) {
                return n1.v - n2.v;
            }
            else {
                return n2.v - n1.v;
            }
        });
        column.forEach(n => {
            d3.select(' .opensankey #g_nodes').datum(n).append('g')
                .attr('id', d => {
                return 'gg_' + d.idNode;
            })
                .attr('class', 'gg_nodes')
                // On gere la visibilité directement sur gg_nodes avec un display <inline />
                // Cela permettra de mieux gérer des zooms sur les éléments visibles
                .style('display', (d) => {
                return 'inline';
            })
                .style('font-family', (d) => {
                return ReturnValueNode(data, d, 'font_family');
            })
                .append('g')
                .attr('id', d => 'ggg_' + d.idNode)
                .attr('class', 'ggg_nodes')
                .attr('transform', d => nodeTransform(applicationData, d, link_function, false));
        });
    });
    updateDrawNodeShape(applicationData, link_function, multi_selected_nodes, Object.values(display_nodes));
    RedrawNodesLabel(applicationData, Object.values(display_nodes), link_function.GetLinkValue, link_function.LinkText, t, node_function);
    AddDrawNodesEvent(contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, GetSankeyMinWidthAndHeight, resizeCanvas);
};
/**
 * Function used to delete visual elements of nodes
 * @param node_to_delete List of nodes id
 */
export const DeleteGNodes = (node_to_delete) => {
    d3.selectAll('.ggg_nodes').filter(n => node_to_delete.includes(n.idNode)).remove();
    d3.selectAll('.gg_nodes').filter(n => node_to_delete.includes(n.idNode)).remove();
};
