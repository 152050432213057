import { format_link_value } from '../import/OpenSankey';
/**
 * Function used to fill the tooltip of link
 * The tooltip is visible when we hover a link and press the key shift
 *
 * @param {SankeyData} data
 * @param {(SankeyLink | SankeyNode)} d
 * @returns {string}
 */
export const AFMLinkTooltipsContent = (data, l, GetLinkValue, trad) => {
    const { tooltip_names } = data;
    const { nodes } = data;
    let t = '<p style=\'text-align: center;margin-bottom:0px\'><b>' + nodes[l.idSource].name.split('\\n').join(' ') + ' -> ' + nodes[l.idTarget].name.split('\\n').join(' ') + '</b></p>';
    if (l.tooltip_text) {
        t += '<p>' + l.tooltip_text.split('\n').join('</br>') + '</p>';
    }
    const link_info = GetLinkValue(data, l.idLink);
    t += extensionTooltipTables(link_info, data, l, trad, nodes, GetLinkValue);
    if (link_info.extension.data_value) {
        t += '<p>Donnée réconciliée :</p>';
    }
    else {
        t += '<p>Donnée déterminée :</p>';
    }
    t += '<table class="table table-striped " >';
    t += '<thead>';
    t += '<tr><th>Valeur</th>';
    const interval = link_info.extension.mini !== undefined && link_info.extension.maxi !== undefined && link_info.extension.mini !== null && link_info.extension.maxi !== null && link_info.extension.mini !== link_info.extension.maxi ? [link_info.extension.mini, link_info.extension.maxi] : undefined;
    if (interval) {
        t += '<th>Bornes d\'incertitudes' + '<td>';
    }
    if (Object.values(data.fluxTags).length > 0) {
        Object.entries(link_info.tags).forEach(([tag_group_key, tags]) => {
            t += '<th>' + data.fluxTags[tag_group_key].group_name + '</th>';
        });
    }
    t += '</tr></thead>';
    t += '<tbody>';
    let the_value = link_info.value;
    t += '<tr><td>' + format_link_value(data, l, the_value, trad) + '</td>';
    if (interval) {
        t += '<td>' + interval[0] + ' - ' + interval[1] + '</td>';
    }
    if (Object.values(data.fluxTags).length > 0) {
        Object.entries(link_info.tags).forEach(([tag_group_key, tags]) => {
            const names = tags.map(link_info_tag => data.fluxTags[tag_group_key].tags[link_info_tag].name);
            t += '<td>' + names.join() + '</td>';
        });
    }
    t += '</tr>';
    t += '</tbody></table>';
    if (link_info.extension.free_mini) {
        const afm_link_info = link_info;
        const mini = afm_link_info.extension.free_mini;
        const maxi = afm_link_info.extension.free_maxi;
        if (mini !== maxi) {
            t += '<table class="table table-striped " >';
            t += '<tbody><tr>';
            t += '<td>Intervalle</td><td>[' + format_link_value(data, l, mini, trad) + '  ' + format_link_value(data, l, maxi, trad) + ']' + '</td>';
            t += '</tr></tbody></table>';
        }
        else {
            t += '<table class="table table-striped " >';
            t += '<tr><td>Valeur</td><td>' + format_link_value(data, l, mini, trad) + '</td>';
        }
        t += '</tr>';
        t += '</tbody></table>';
    }
    if (l.tooltips) {
        let title = false;
        const count = tooltip_names.filter((tooltip_name, i) => {
            return (l.tooltips && l.tooltips[i] !== undefined && l.tooltips[i] !== null);
        }).length;
        const width = 100 / count + 'px';
        tooltip_names.forEach((tooltip_name, i) => {
            if (l.tooltips && l.tooltips[i] !== undefined && l.tooltips[i] !== null) {
                if (title === false) {
                    t += '<p>Hypothèses</p>';
                    t += '<table class="table table-striped " >';
                    t += '<thead><tr>';
                    title = true;
                }
                t += '<th width="' + width + '">' + tooltip_name[0] + '</th>';
            }
        });
        t += '</tr></thead>';
        t += '<tr><tbody>';
        tooltip_names.forEach((_, i) => {
            if (l.tooltips && l.tooltips[i] !== undefined && l.tooltips[i] !== null) {
                if (typeof l.tooltips[i] === 'number') {
                    if (tooltip_names[i].includes('%')) {
                        t += '<td>' + format_link_value(data, l, Number(l.tooltips[i]) * 100, trad) + '</td>';
                    }
                    else {
                        t += '<td>' + format_link_value(data, l, +l.tooltips[i], trad) + '</td>';
                    }
                }
                else {
                    t += '<td>' + l.tooltips[i] + '</td>';
                }
            }
        });
        t += '</tr></tbody></table>';
    }
    return t;
};
export const extensionTooltipTables = (link_info, data, l, trad, nodes, GetLinkValue) => {
    let desagregate_source_nodes = new Set;
    let desagregate_target_nodes = new Set;
    let t = '';
    if (link_info.extension.data_value) {
        t += '<p>Donnée collectée </p>';
        t += '<table class="table table-striped " >';
        t += '<thead><tr>';
        t += '<th>Valeur</th>';
        if (link_info.extension.data_source) {
            t += '<th>Source</th>';
        }
        if (Object.values(data.fluxTags).length > 0) {
            Object.entries(link_info.tags).forEach(([tag_group_key, tags]) => {
                t += '<th>' + data.fluxTags[tag_group_key].group_name + '</th>';
            });
        }
        t += '</tr></thead>';
        t += '<tbody><tr>';
        t += '<td>' + format_link_value(data, l, +link_info.extension.data_value, trad) + '</td>';
        if (link_info.extension.data_source) {
            t += '<td>' + link_info.extension.data_source + '</td>';
        }
        if (Object.values(data.fluxTags).length > 0) {
            Object.entries(link_info.tags).forEach(([tag_group_key, tags]) => {
                const names = tags.map(link_info_tag => data.fluxTags[tag_group_key].tags[link_info_tag].name);
                t += '<td>' + names.join(' ') + '</td>';
            });
        }
        t += '</tr></tbody></table>';
    }
    else if (link_info.extension.data_min) {
        t += '<p>Bornes collectées </p>';
        t += '<table class="table table-striped " >';
        t += '<thead><tr>';
        t += '<th>Minimum</th><th>Maximum</th>';
        if (link_info.extension.data_source) {
            t += '<th>Source</th>';
        }
        t += '</tr><tbody><tr>';
        t += '<td>' + link_info.extension.data_min + '</td>';
        t += '<td>' + link_info.extension.data_max + '</td>';
        if (link_info.extension.data_source) {
            t += '<td>' + link_info.extension.data_source + '</td>';
        }
        t += '</tr></tbody></table>';
    }
    else {
        const source_node = nodes[l.idSource];
        const target_node = nodes[l.idTarget];
        desagregate_source_nodes = extractDesagregationNodes(data, source_node);
        desagregate_target_nodes = extractDesagregationNodes(data, target_node);
        desagregate_target_nodes.add(target_node);
        desagregate_source_nodes.add(source_node);
        t = WriteChildrenTable([...desagregate_source_nodes], [...desagregate_target_nodes], data, GetLinkValue, trad);
    }
    return t;
};
/**
 * Description placeholder
 *
 * @param {SankeyNode[]} desagregate_source_nodes
 * @param {SankeyNode[]} desagregate_target_nodes
 * @param {SankeyData} data
 * @param {string} t
 * @param {SankeyLink} l
 * @returns {string}
 */
export const WriteChildrenTable = (desagregate_source_nodes, desagregate_target_nodes, data, GetLinkValue, trad) => {
    const { nodes } = data;
    let t = '';
    let header_written = false;
    desagregate_source_nodes.forEach(n1 => {
        desagregate_target_nodes.forEach(n2 => {
            const desagregated_link = Object.values(data.links).filter(l => l.idSource === n1.idNode && l.idTarget === n2.idNode)[0];
            if (desagregated_link === undefined) {
                return;
            }
            const desagregated_link_info = GetLinkValue(data, desagregated_link.idLink);
            if (desagregated_link_info.extension.data_value) {
                if (!header_written) {
                    t += '<p>Données collectées sur les flux désagrégés </p>';
                    t += '<table class="table table-striped " >';
                    t += '<thead><tr>';
                    t += '<th>Flux désagrégés</th>';
                    t += '<th>Valeur</th>';
                    t += '<th>Source</th>';
                    if (Object.values(data.fluxTags).length > 0) {
                        Object.entries(desagregated_link_info.tags).forEach(([tag_group_key, tags]) => {
                            t += '<th>' + data.fluxTags[tag_group_key].group_name + '</th>';
                        });
                    }
                    t += '</tr></thead>';
                    t += '<tbody>';
                    header_written = true;
                }
                let value_to_display = +desagregated_link_info.extension['data_value'];
                t += '<tr>';
                t += '<td>' + nodes[desagregated_link.idSource].name + '->' + nodes[desagregated_link.idTarget].name + '</td>';
                t += '<td>' + format_link_value(data, desagregated_link, value_to_display, trad) + '</td>';
                if (desagregated_link_info.extension.data_source) {
                    t += '<td>' + desagregated_link_info.extension.data_source + '</td>';
                }
                else {
                    t += '<td></td>';
                }
                if (Object.values(data.fluxTags).length > 0) {
                    Object.entries(desagregated_link_info.tags).forEach(([tag_group_key, tags]) => {
                        const names = tags.map(link_info_tag => data.fluxTags[tag_group_key].tags[link_info_tag].name);
                        t += '<td>' + names.join() + '</td>';
                    });
                }
                t += '<td></td>';
            }
            t += '</tr>';
        });
    });
    if (header_written) {
        t += '</tr></tbody></table>';
    }
    return t;
};
export const extractDesagregationNodes = (data, node) => {
    const dim_desagregate_nodes = Object.values(data.nodes).filter(nn => {
        let is_children = false;
        Object.values(data.levelTags).forEach(tagg => {
            if (nn.dimensions[tagg.group_name] && nn.dimensions[tagg.group_name].parent_name === node.idNode) {
                is_children = true;
            }
        });
        return is_children;
    });
    let all_nodes = new Set(dim_desagregate_nodes);
    dim_desagregate_nodes.forEach(n => {
        all_nodes = new Set([...all_nodes, ...extractDesagregationNodes(data, n)]);
    });
    return all_nodes;
};
