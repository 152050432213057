import * as d3 from 'd3';
import { TextNodeValue, NodeLabelPosX, NodeLabelPosY, NodeLabelValuePosX, NodeLabelValuePosY, NodeLabeLText, TextNodeWrap, returnScaleOfDrawArea } from './SankeyDrawFunction';
import { ReturnValueNode, defaultElementColor } from '../configmenus/SankeyUtils';
import { windowSankey } from '../configmenus/SankeyUtils';
import { dragNodeTextEventWidthBoxEvent } from './SankeyDragNodes';
export const updateDrawAllNodesLabel = (applicationData, GetLinkValue, LinkText, t, node_function) => {
    RedrawNodesLabel(applicationData, [], GetLinkValue, LinkText, t, node_function);
};
export const RedrawNodesLabel = (applicationData, nodes_to_redraw, GetLinkValue, LinkText, t, node_function) => {
    const { data, display_nodes, display_links } = applicationData;
    //------------------LABEL------------------------
    // Add node label and apply parameter
    const ggg_nodes = d3.selectAll('.ggg_nodes');
    const filtered_gggnodes = ggg_nodes.filter(n => nodes_to_redraw.length > 0 ? nodes_to_redraw.includes(n) : true);
    filtered_gggnodes.selectAll('.label_background,.node_text,.fo_input_label').remove();
    const bg_text_node = filtered_gggnodes
        .filter(n => ReturnValueNode(data, n, 'label_visible') && ReturnValueNode(data, n, 'label_background'))
        .append('rect')
        .attr('class', 'label_background')
        .attr('id', n => 'label_bg_for_' + n.idNode)
        .attr('fill', 'white')
        .attr('fill-opacity', 0.55)
        .attr('rx', 4);
    filtered_gggnodes
        .filter(n => {
        return ReturnValueNode(data, n, 'label_visible');
    })
        .append('text')
        .attr('fill', n => (ReturnValueNode(data, n, 'label_color')) ? 'white' : 'black')
        .classed('node', true)
        .classed('node_text', true)
        .classed('test_new_file', true)
        .attr('id', n => 'text_' + n.idNode)
        .attr('x', n => NodeLabelPosX(data, n))
        .attr('y', n => NodeLabelPosY(data, n))
        .attr('text-anchor', n => {
        if (n.x_label) {
            return 'start';
        }
        if (ReturnValueNode(data, n, 'label_horiz') == 'middle') {
            return 'middle';
        }
        else if (ReturnValueNode(data, n, 'label_horiz') == 'left') {
            return 'end';
        }
        else if (ReturnValueNode(data, n, 'label_horiz') == 'right') {
            return 'start';
        }
        else {
            return 'start';
        }
    })
        .style('text-align', 'center')
        .style('font-weight', n => (ReturnValueNode(data, n, 'bold')) ? 'bold' : 'normal')
        .style('font-style', n => (ReturnValueNode(data, n, 'italic')) ? 'italic' : 'normal')
        .style('font-size', n => ReturnValueNode(data, n, 'font_size') + 'px')
        .style('font-family', n => ReturnValueNode(data, n, 'font_family'))
        .style('text-transform', n => (ReturnValueNode(data, n, 'uppercase')) ? 'uppercase' : 'none')
        .text(n => NodeLabeLText(data, n))
        .each(n => TextNodeWrap(n, data));
    if (!windowSankey.SankeyToolsStatic) {
        // Add an input to change the name of the node
        // The input appear when we double click on the label
        filtered_gggnodes
            .append('foreignObject')
            .attr('id', d => 'fo_input_label_' + d.idNode)
            .attr('class', 'fo_input_label')
            .attr('x', (n) => NodeLabelPosX(data, n))
            .attr('y', (n) => NodeLabelPosY(data, n))
            .style('width', d => ((d.name.length)) + 'rem')
            .attr('height', d => Number(ReturnValueNode(data, d, 'font_size')) + 2)
            .style('display', 'none')
            .append('xhtml:div')
            .append('input')
            .attr('id', d => 'input_label_' + d.idNode)
            .attr('class', 'input_label')
            .attr('type', 'text')
            .attr('value', d => d.name)
            .style('font-size', n => ReturnValueNode(data, n, 'font_size') + 'px')
            .on('input', (evt, d) => { d.name = evt.target.value; })
            .style('background-color', 'white')
            .style('border', 'none')
            .style('border-color', 'transparent')
            .style('height', d => Number(ReturnValueNode(data, d, 'font_size')) + 'px')
            .style('outline', 'none')
            .on('blur', (evt, n) => {
            node_function.RedrawNodes([n]);
        });
    }
    if (d3.select('.opensankey #svg').node()) {
        const scale_svg = returnScaleOfDrawArea();
        bg_text_node.attr('x', n => {
            var _a, _b, _c, _d;
            if (n.x_label) {
                return n.x_label;
            }
            else {
                const box_zdd = (_b = (_a = document.getElementById('ggg_' + n.idNode)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) !== null && _b !== void 0 ? _b : { x: 0, y: 0, width: 0 };
                const size_shape = +d3.select('#shape_' + n.idNode).attr('width');
                const box_text = (_d = (_c = document.getElementById('text_' + n.idNode)) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect()) !== null && _d !== void 0 ? _d : { x: 0, y: 0, width: 0 };
                let horiz_shift = 0;
                const pos_h = ReturnValueNode(data, n, 'label_horiz');
                if (pos_h == 'left') {
                    horiz_shift = box_text.width - +ReturnValueNode(data, n, 'label_horiz_shift');
                }
                else if (pos_h == 'middle' && size_shape < box_text.width) {
                    horiz_shift = (box_text.width - size_shape) / 2;
                }
                return ((box_text.x) - box_zdd.x - horiz_shift) / scale_svg - 2;
            }
        })
            .attr('y', n => {
            const font_size = ReturnValueNode(data, n, 'font_size');
            if (n.y_label) {
                return n.y_label;
            }
            else {
                const pos_y = ReturnValueNode(data, n, 'label_vert');
                const org_text_pos = NodeLabelPosY(data, n);
                let shift_y = 0;
                const nb_tspan = d3.selectAll(' .opensankey #ggg_' + n.idNode + ' text tspan').nodes().length;
                if (pos_y === 'top') {
                    shift_y = (nb_tspan * font_size);
                }
                else if (pos_y === 'middle') {
                    if (nb_tspan === 1) {
                        shift_y = font_size;
                        const shift = (0.25 * ReturnValueNode(data, n, 'font_size'));
                        shift_y = shift_y - shift;
                    }
                    else {
                        shift_y = (nb_tspan + 0.5) * font_size / 2;
                    }
                }
                return org_text_pos - shift_y;
            }
        })
            .attr('width', n => {
            var _a, _b;
            return (((_b = (_a = document.getElementById('text_' + n.idNode)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width) !== null && _b !== void 0 ? _b : 0)) / scale_svg + 4;
        })
            .attr('height', n => {
            var _a, _b;
            const nb_tspan = d3.selectAll(' .opensankey #ggg_' + n.idNode + ' text tspan').nodes().length;
            if (nb_tspan === 1) {
                return (((_b = (_a = document.getElementById('text_' + n.idNode)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height) !== null && _b !== void 0 ? _b : 0)) / scale_svg + 4;
            }
            else {
                return ((nb_tspan + 0.5) * ReturnValueNode(data, n, 'font_size'));
            }
        });
    }
    // Display value of nodes
    // Value of nodes are the maximum between the sum of input links and the sum of output links
    filtered_gggnodes
        .filter(n => ReturnValueNode(data, n, 'show_value'))
        .append('text')
        .classed('node', true)
        .classed('node_text_value', true)
        .attr('id', n => 'text_value_' + n.idNode)
        .attr('x', n => NodeLabelValuePosX(data, n))
        .attr('y', n => NodeLabelValuePosY(data, n))
        .attr('text-anchor', (n) => ReturnValueNode(data, n, 'label_horiz_valeur').replace('left', 'end').replace('right', 'start'))
        .style('font-family', n => ReturnValueNode(data, n, 'font_family'))
        .style('font-size', n => ReturnValueNode(data, n, 'value_font_size') + 'px')
        .text(n => TextNodeValue(n, data, display_links, display_nodes, GetLinkValue, LinkText, t));
    // Drag zone for changing label box width
    // (if the label length exceed a certian length the text is wrapped, the box visually represent the length to not exceed)
    filtered_gggnodes
        .filter(n => n.x_label == undefined)
        .filter(n => ReturnValueNode(data, n, 'label_visible'))
        .append('rect')
        .attr('class', 'box_width_threshold')
        .attr('x', n => {
        const nn = n;
        const width = +d3.select(' .opensankey #shape_' + nn.idNode).attr('width');
        if (nn.x_label) {
            return nn.x_label;
        }
        else if (ReturnValueNode(data, nn, 'label_horiz') == 'middle') {
            return width / 2 - ReturnValueNode(data, nn, 'label_box_width') / 2;
        }
        else if (ReturnValueNode(data, nn, 'label_horiz') == 'left') {
            return -ReturnValueNode(data, nn, 'label_box_width');
        }
        else if (ReturnValueNode(data, nn, 'label_horiz') == 'right') {
            return width + +ReturnValueNode(data, nn, 'label_horiz_shift');
        }
        else {
            return 0 + +ReturnValueNode(data, nn, 'label_horiz_shift');
        }
    })
        .attr('y', n => {
        const nn = n;
        const height = +d3.select(' .opensankey #shape_' + nn.idNode).attr('height');
        if (nn.y_label && data.show_structure !== 'structure') {
            return nn.y_label;
        }
        else if (ReturnValueNode(data, nn, 'label_vert') == 'middle') {
            return 0;
        }
        else if (ReturnValueNode(data, nn, 'label_vert') == 'top') {
            return -4;
        }
        else if (ReturnValueNode(data, nn, 'label_vert') == 'bottom') {
            return height;
        }
        else {
            return 0;
        }
    })
        .attr('width', n => ReturnValueNode(data, n, 'label_box_width'))
        .attr('height', n => {
        var _a;
        const h = (_a = document.getElementById('text_' + n.idNode)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height;
        return (h != undefined) ? h : 25;
    })
        .attr('fill', defaultElementColor)
        .attr('fill-opacity', '0')
        .attr('stroke', defaultElementColor)
        .attr('stroke-width', '2px')
        .attr('cursor', 'ew-resize')
        //.attr('visibility',n=>(nodes_to_redraw.length==1 && nodes_to_redraw.includes(n as SankeyNode)?'visible':'hidden'))
        .attr('visibility', 'hidden')
        // .filter(()=>windowSankey.SankeyToolsStatic!==true)
        .call(dragNodeTextEventWidthBoxEvent(data, node_function));
};
