import React, { useState } from 'react';
import FileSaver from 'file-saver';
import { Checkbox, Tag } from '@chakra-ui/react';
import { LinkVisibleOnSvg, NodeVisibleOnsSvg } from './import/OpenSankey';
export const SankeyDevInputDialogSaveJSON = ({ t, elementsSelected, }) => {
    const [to_beautify, set_to_beautify] = useState(false);
    elementsSelected.ref_getter_beautify.current = to_beautify;
    return React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: to_beautify, onChange: () => set_to_beautify(!to_beautify) },
        t('Menu.toBeautify'),
        " ",
        React.createElement(Tag, { colorScheme: "teal", style: { marginLeft: 'auto' } }, "Dev"));
};
export const SankeyDevClickSaveDiagram = (applicationData, data, elementsSelected, options) => {
    const cpy = JSON.parse(JSON.stringify(data));
    if (!options.mode_save) {
        Object.values(cpy.links).forEach(d => {
            d.value = {};
        });
    }
    if (options.mode_visible_element) {
        // Si l'on enregistre que les element visible alors on cherche les élements visible dasns le svg
        const link_present = LinkVisibleOnSvg();
        const node_visible = NodeVisibleOnsSvg();
        cpy.links = Object.fromEntries(Object.entries(cpy.links).filter(l => link_present.includes(l[0])).map(l => l));
        const key_level_tags = Object.keys(cpy.levelTags);
        cpy.nodes = Object.fromEntries(Object.entries(cpy.nodes).filter(n => node_visible.includes(n[0])).map(n => {
            key_level_tags.forEach(klt => {
                delete n[1].tags[klt];
            });
            n[1].dimensions = {};
            n[1].inputLinksId = n[1].inputLinksId.filter(lid => link_present.includes(lid));
            n[1].outputLinksId = n[1].outputLinksId.filter(lid => link_present.includes(lid));
            return n;
        }));
        cpy.levelTags = {};
        cpy.linkZIndex = link_present;
        cpy.view = [];
    }
    //RecomputeTrade(cpy)
    //const str_data = JSON.stringify(cpy)
    // const data_to_save = { ...data } as unknown as OSPApplicationDataType
    const str_data = JSON.stringify(cpy, null, 2);
    const blob = new Blob([str_data], { type: 'text/plain;charset=utf-8' });
    let name = 'Diagramme de Sankey';
    if (cpy.view.length > 0 && !cpy.is_catalog) {
        name = 'Diagramme de Sankey avec vues';
    }
    else if (cpy.is_catalog) {
        name = 'Catalogue de vues de diagrammes de Sankey';
    }
    FileSaver.saveAs(blob, name + '.json');
};
